import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import arrowIcon from '../../assets/arrow.png';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getReportDetails } from '../../Services/report.service';
import env from '../../constants/envVars';
import ReportSubDetails from './ReportSubDetails';

const ReportDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { reportDetails } = useSelector((state) => state.report);

  useEffect(() => {
    if (id) dispatch(getReportDetails(id));
  }, [id]);

  return (
    <div className='container-fluid'>
      <div className='row row-100vh'>
        <div className='col-md-12 top mt-5 px-104'>
          <Link to={'/reporting'}>
            <img src={arrowIcon} alt='image' className='img-fluid' />
          </Link>
          <h4 className='my-3 text-color'>Report Details</h4>
        </div>
        <div className='col-md-12 middle px-104'>
          <hr />
          <div className='card mb-3 border-0'>
            <div className='card-body px-0'>
              <h5 className='text-black fw-semibold mb-3'>Service Details</h5>
              <div className='row'>
                <div className='col-md-9'>
                  <div className='mb-3'>
                    <p className='text-muted mb-0'>Job ID</p>
                    <h6 className='text-color fw-semibold'>{reportDetails?.job_no}</h6>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='mb-3 float-end'>
                    <p className='text-muted mb-0'>Report Date</p>
                    <h6 className='text-color fw-semibold'>{moment(reportDetails?.createdAt).format('MMM DD YYYY')}</h6>
                  </div>
                </div>
                <div className='col-md-9'>
                  <div className='mb-3'>
                    <p className='text-muted mb-0'>Company Name</p>
                    <h6 className='text-color fw-semibold'>Pestea</h6>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='mb-3 float-end'>
                    <p className='text-muted mb-0'>Service Type</p>
                    <h6 className='text-color fw-semibold'>{'Pest Control'}</h6>
                  </div>
                </div>
                <div className='col-md-9'>
                  <div className='mb-3'>
                    <p className='text-muted mb-0'>Technician Name</p>
                    <h6 className='text-color fw-semibold'>{reportDetails?.technician_id?.full_name}</h6>
                  </div>
                </div>
              </div>

              <div className='report-subdetails'>
                {reportDetails?.report_ids?.map((report) => (
                  <ReportSubDetails reportDetails={report} />
                ))}
              </div>

              <hr />
              <h5 className='text-black fw-semibold mb-3 mt-4'>Service Summary</h5>
              <ul className='list-group'>
                <li className='list-group-item border-0 px-0 h6 text-muted d-flex justify-content-between align-items-center'>
                  <h5 className='text-black fw-semibold mt-2'>Sub total</h5>
                  <span className=' text-color'>{reportDetails?.subtotal?.toFixed(2)}</span>
                </li>
                <li className='list-group-item border-0 px-0 h6 text-muted d-flex justify-content-between align-items-center'>
                  <h5 className='text-black fw-semibold'>Signed by</h5>
                  <span className='text-color d-block'>{reportDetails?.signed_by}</span>
                </li>
                {/* <li className="list-group-item border-0 px-0 h6 text-muted d-flex justify-content-between align-items-center">
                  Total Discounts
                  <span className=" text-color">
                    -{reportDetails?.discount?.toFixed(2)}
                  </span>
                </li>
                <li className="list-group-item border-0 px-0 h6 text-muted d-flex justify-content-between align-items-center">
                  Tax
                  <span className="text-color">
                    {reportDetails?.tax?.toFixed(2)}
                  </span>
                </li>
                <li className="list-group-item border-0 px-0 h6 text-muted d-flex justify-content-between align-items-center">
                  Total
                  <span className="text-color">
                    {reportDetails?.total?.toFixed(2)}
                  </span>
                </li> */}
              </ul>
              {/* <div className="clearfix mt-4">
                <div className="float-start">
                  <h5 className="mb-0 text-black">Status</h5>
                </div>
                <div className="float-end">
                  <h5 className="mb-0 text-color fw-bold">
                    <small>
                      {reportDetails?.job_id?.is_completed
                        ? "Completed"
                        : "Pending"}
                    </small>
                  </h5>
                </div>
              </div> */}
              <div className='clearfix mt-4'>
                <div className='float-start'>
                  <h5 className='mb-0 text-black'>Signature</h5>
                </div>
                <div className='float-end'>
                  <img className='sign' src={env.api_url + '/' + reportDetails?.signature} alt='Signature' />
                </div>
              </div>
              {/* <div className='mt-5 text-decoration-underline'>
                <h5>Media for Report</h5>



              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="col-md-12 bottom">
          <div className="text-center">
            <img
              src={buttonLineIcon}
              alt="line"
              className=" bottom-line img-fluid"
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ReportDetails;

import React, { useState } from 'react';
import MediaModal from './MediaModal';

const ReportSubDetails = ({ reportDetails }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [mediaModalShow, setMediaModalShow] = useState(false);

  const openMediaModal = () => setMediaModalShow(true);
  const closeMediaModal = () => setMediaModalShow(false);

  return (
    <>
      <MediaModal show={mediaModalShow} handleClose={closeMediaModal} data={reportDetails?.media} />
      <div className='d-flex flex-column'>
        <h4 className='text-black mb-3 mt-3'>Job No: {`#${reportDetails?.job_id?.job_no}`}</h4>
        <h5 className='text-black mb-3 mt-3'>Pests</h5>
        {reportDetails?.pests?.map((pest) => (
          <h6 className='text-color'>{pest?.name}</h6>
        ))}
        <h5 className='text-black mb-3 mt-3'>Products</h5>
        {reportDetails?.job_id?.products?.map((product) => (
          <h6 className='text-color'>{product?.product_id?.name}</h6>
        ))}
        {/* <h5 className="text-black mb-3 mt-3">Chemicals</h5>
      {reportDetails?.chemicals?.map((chemical) => (
        <h6 className="text-color">{chemical?.name}</h6>
      ))} */}
        {/* <h5 className="text-black mb-3 mt-3">Tools</h5>
      {reportDetails?.tools?.map((tool) => (
        <h6 className="text-color">{tool?.name}</h6>
      ))} */}
        <h5 className='text-black mb-3 mt-3'>Recommendations</h5>
        <span>{reportDetails?.recommendations || 'N/A'}</span>

        {reportDetails?.media?.length ? (
          <button onClick={openMediaModal} className='mt-3 view-media-btn'>
            View Media
          </button>
        ) : (
          <></>
        )}

        {reportDetails?.documents?.length ? (
          <div>
            {reportDetails?.documents?.map((doc, i) => (
              <a className='mt-2 view-doc-anchor' key={i} href={baseUrl + '/' + doc} target='_blank' rel='noreferrer'>
                View Document {i + 1}
              </a>
            ))}
          </div>
        ) : (
          <></>
        )}

        <hr />
      </div>
    </>
  );
};

export default ReportSubDetails;

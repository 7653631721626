import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import horixontalLogo from '../../assets/horixontal-logo.png';
import setting from '../../assets/setting.svg';
import home from '../../assets/home.svg';
import schedule from '../../assets/schedule.svg';
import scheduleActive from '../../assets/find.png';
import invoices from '../../assets/invoices.svg';
import invoicesActive from '../../assets/Vector-active.png';
import services from '../../assets/services.svg';
import reportIcon from '../../assets/reporting.svg';
import reportActiveIcon from '../../assets/reportActive.png';

import homeWhiteIcon from '../../assets/home-white-icon.png';

const Layout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    let className = 'my-component';
    let classNameSchedule = 'my-component';
    let classNameInvoice = 'my-component';
    let classNameReport = 'my-component';

    if (currentPath === '/dashboard') {
        className += ' active';
    }
    if (currentPath === '/schedule') {
        classNameSchedule += ' active';
    }
    if (currentPath === '/invoice') {
        classNameInvoice += ' active';
    }
    if (currentPath === '/reporting') {
        classNameReport += ' active';
    }

    const handleReportingClick = () => {
        // Navigate to the Reporting page
        navigate('/reporting');
    };

    return (
        <>
            {currentPath === '/dashboard' && (
                <div>
                    <div className='col-md-12 mt-5 px-md-5'>
                        <div className='d-flex  align-items-center  justify-content-between clearfix'>
                            <div className='float-start w-100'>
                                <img src={horixontalLogo} alt='logo' className='img-fluid' />
                            </div>
                            <div className='float-end'>
                                <Link to={'/setting'}>
                                    <img src={setting} alt='setting' className='img-fluid' />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <h4 className='text-color my-3 ms-5 ms-0'>Welcome</h4>
                    </div>
                </div>
            )}

            {currentPath === '/schedule' && (
                <div className='col-md-12 mt-5 mb-4 px-md-5'>
                    <div className='d-flex clearfix'>
                        <div className='float-start w-100'>
                            <h3 className='text-color text-center'>Schedule</h3>
                        </div>
                        <div className='float-end'>
                            <Link to={'/setting'}>
                                <img src={setting} alt='setting' className='img-fluid' />
                            </Link>
                        </div>
                    </div>
                </div>
            )}
            {currentPath === '/invoice' && (
                <div className='col-md-12 mt-5 mb-4 px-md-5'>
                    <div className='d-flex clearfix'>
                        <div className='float-start w-100'>
                            <h3 className='text-color text-center'>Invoices</h3>
                        </div>
                        <div className='float-end'>
                            <Link to={'/setting'}>
                                <img src={setting} alt='setting' className='img-fluid' />
                            </Link>
                        </div>
                    </div>{' '}
                </div>
            )}
            {currentPath === '/reporting' && (
                <div className='col-md-12 mt-5 mb-4 px-md-5'>
                    <div className='d-flex clearfix'>
                        <div className='float-start w-100'>
                            <h3 className='text-color text-center'>Treatment reports</h3>
                        </div>
                        <div className='float-end'>
                            <Link to={'/setting'}>
                                <img src={setting} alt='setting' className='img-fluid' />
                            </Link>
                        </div>
                    </div>
                </div>
            )}

            <nav
                className={`navbar navbar-expand-lg navbar-dark sticky-top bg-button ${
                    currentPath === '/schedule' || currentPath === '/invoice' || currentPath === '/reporting'
                        ? 'new'
                        : ''
                }`}
            >
                <div className='container-fluid px-md-5'>
                    <button
                        className='navbar-toggler'
                        type='button'
                        data-bs-toggle='offcanvas'
                        data-bs-target='#offcanvasNavbar'
                        aria-controls='offcanvasNavbar'
                    >
                        <span className='navbar-toggler-icon'></span>
                    </button>
                    <div
                        className='offcanvas offcanvas-start'
                        tabindex='-1'
                        id='offcanvasNavbar'
                        aria-labelledby='offcanvasNavbarLabel'
                    >
                        <div className='offcanvas-header'>
                            <h5 className='offcanvas-title text-white fw-bolder' id='offcanvasNavbarLabel'>
                                Menu
                            </h5>
                        </div>
                        <div className='offcanvas-body d-block'>
                            <ul className='navbar-nav nav-justified'>
                                <li className='nav-item ml-80'>
                                    <Link
                                        className='nav-link active text-white d-flex align-items-center'
                                        aria-current='page'
                                        to={'/dashboard'}
                                    >
                                        <img
                                            src={currentPath === '/dashboard' ? home : homeWhiteIcon}
                                            alt='image'
                                            className='img-fluid me-2 image-width'
                                        />
                                        <span className={className}>Home</span>
                                    </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link
                                        className='nav-link text-white d-flex align-items-center'
                                        aria-current='page'
                                        to={'/schedule'}
                                    >
                                        <img
                                            src={currentPath === '/schedule' ? scheduleActive : schedule}
                                            alt='image'
                                            className='img-fluid me-2 image-width'
                                        />
                                        <span className={classNameSchedule}>Schedule</span>
                                    </Link>
                                </li>
                                {/* <li className='nav-item'>
                                    <Link
                                        className='nav-link text-white d-flex align-items-center'
                                        aria-current='page'
                                        to={'/invoice'}
                                    >
                                        <img
                                            src={currentPath === '/invoice' ? invoicesActive : invoices}
                                            alt='image'
                                            className='img-fluid me-2 image-width'
                                        />
                                        <span className={classNameInvoice}>Invoices</span>
                                    </Link>
                                </li>
                                <li className='nav-item dropdown'>
                                    <Link
                                        className='nav-link dropdown-toggle gg text-white d-flex align-items-center'
                                        to={'/services'}
                                        role='button'
                                        data-bs-toggle='dropdown'
                                        aria-expanded='false'
                                    >
                                        <img src={services} alt='image' className='img-fluid me-2 image-width' />
                                        Services
                                    </Link>
                                    <ul className='dropdown-menu'>
                                        <li>
                                            <a className='dropdown-item' href='#'>
                                                Documents
                                            </a>
                                        </li>
                                    </ul>
                                </li> */}
                                <li className='nav-item dropdown'>
                                    <Link
                                        onClick={handleReportingClick}
                                        className='nav-link dropdown-toggle gg text-white d-flex align-items-center'
                                        to={'/reporting'}
                                        role='button'
                                        data-bs-toggle='dropdown'
                                        aria-expanded='false'
                                    >
                                        <img
                                            src={currentPath === '/reporting' ? reportActiveIcon : reportIcon}
                                            alt='image'
                                            className='img-fluid me-2 image-width'
                                        />{' '}
                                        <span className={classNameReport}>Treatment reports</span>
                                    </Link>
                                    <ul className='dropdown-menu'>
                                        <li>
                                            <a className='dropdown-item' href='#'>
                                                Approved List of Pesticides
                                            </a>
                                        </li>
                                        <li>
                                            <a className='dropdown-item' href='#'>
                                                Pestea Call out List
                                            </a>
                                        </li>
                                        <li>
                                            <a className='dropdown-item' href='#'>
                                                Certificates & Company Documents
                                            </a>
                                        </li>
                                        <li>
                                            <a className='dropdown-item' href='#'>
                                                Washroom Hygiene Safety Datre Sheets
                                            </a>
                                        </li>
                                        <li>
                                            <a className='dropdown-item' href='#'>
                                                Pest Control Safety Date Sheets
                                            </a>
                                        </li>
                                        <li>
                                            <a className='dropdown-item' href='#'>
                                                Product Labels
                                            </a>
                                        </li>
                                        <li>
                                            <a className='dropdown-item' href='#'>
                                                Service Level Aggrement
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Layout;

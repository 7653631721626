import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';

function MediaCarousel({ images }) {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  return (
    <Carousel>
      {images?.map((imageFileName, i) => {
        return (
          <Carousel.Item key={i}>
            <div className='cara-image-container'>
              <img className='cara-report-image' src={`${baseUrl}/${imageFileName}`} />
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default function MediaModal({ show, handleClose, ...props }) {
  return (
    <Modal show={show} onHide={handleClose} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Media for Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MediaCarousel images={props.data} />
      </Modal.Body>
    </Modal>
  );
}
